var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.elemId } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.selectorTitleLabel,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "no-close-on-esc": _vm.isCellEditing || _vm.editorShow,
            "modal-class": [_vm.elemId],
            "content-class": "shadow",
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectorShow,
            callback: function ($$v) {
              _vm.selectorShow = $$v
            },
            expression: "selectorShow",
          },
        },
        [
          _vm.alertObj.msg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertObj.msg,
                  details: _vm.alertObj.msgDetails.list,
                  detailTitle: _vm.alertObj.msgDetails.title,
                  alertState: _vm.alertObj.state,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "grid-toolbar" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "ml-1",
                  attrs: { id: `BTN_REFRESH_${_vm.elemId}` },
                  on: { click: _vm.reloadData },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "arrows-rotate"] },
                  }),
                  _c("b-popover", {
                    attrs: {
                      target: `BTN_REFRESH_${_vm.elemId}`,
                      placement: "top",
                      boundary: "viewport",
                      triggers: "hover",
                      content: _vm.$t("button.refresh"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-btn",
                {
                  attrs: { id: `BTN_EXPORT_DOCUMENT_${_vm.elemId}` },
                  on: { click: _vm.fileExport },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "inbox-out"] },
                  }),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `BTN_EXPORT_DOCUMENT_${_vm.elemId}`,
                        triggers: "hover",
                        placement: "top",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("export_system_status")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ag-grid-vue", {
            staticClass:
              "ag-theme-balham generic-selector selector-grid-height spreadsheet",
            staticStyle: { width: "100%" },
            attrs: {
              id: "rebate-grid",
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: (params) =>
                typeof params.data.uuId !== "undefined"
                  ? `${params.data.uuId}${params.data.path}`
                  : params.data.name,
              serverSideInfiniteScroll: true,
              sideBar: false,
              suppressContextMenu: "",
              getDataPath: (data) => data.path.split(", "),
              rowData: _vm.rowData,
              treeData: "",
              groupDefaultExpanded: "-1",
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              suppressGroupRowsSticky: "",
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }